<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" @click="operation.add.click">添加日志</en-button>
    </template>

    <flex-box>
      <template #default="{ height }">
        <en-scrollbar :height="height" view-class="flex flex-col gap-6">
          <div v-for="item of table.data" class="p-4 border-1 border-solid border-gray-300 divide-y flex flex-col gap-4">
            <div class="flex items-center justify-between">
              <en-button type="primary" link @click="table.title.click(item)">{{ item.title }}</en-button>
              <span class="flex-1 flex justify-end pr-4">{{ formatDate(item.launchDate, 'YYYY-MM') }}</span>
              <span>{{ item.status?.message }}</span>
            </div>
            <div>
              <en-form label-position="left">
                <en-form-item label="软件版本">{{ item.versionNumber }}</en-form-item>
                <en-form-item label="更新内容"><div v-html="item.content" class="max-h-10 overflow-hidden"></div></en-form-item>
              </en-form>
              <div class="flex justify-end">
                <en-button type="primary" @click="table.preview.click(item)">查看完整内容</en-button>
              </div>
            </div>
          </div>
        </en-scrollbar>
      </template>
    </flex-box>
  </view-item-setting>

  <en-drawer v-model="detail.visible" :title="`${detail.form.data.id ? '编辑' : '添加'}日志`" size="800">
    <en-form :model="detail.form.data" :rules="detail.form.rules" :ref="setRef('detailForm')">
      <en-form-item label="版本号" prop="versionNumber">
        <en-input v-model="detail.form.data.versionNumber"></en-input>
      </en-form-item>
      <en-form-item label="日志标题" prop="title">
        <en-input v-model="detail.form.data.title"></en-input>
      </en-form-item>
      <en-form-item label="副标题" prop="subtitle">
        <en-input v-model="detail.form.data.subtitle"></en-input>
      </en-form-item>
      <en-form-item label="上线日期" prop="launchDate">
        <en-date-picker v-model="detail.form.data.launchDate" class="w-full"></en-date-picker>
      </en-form-item>
      <en-form-item label="重要性">
        <select-maintain
          v-model="detail.form.data.level"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => {
              params.paths = ['RNLEVEL']
            }
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="发送对象">
        <select-maintain
          v-model="detail.form.data.targetType"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => {
              params.paths = ['RNTGTTYPE']
            }
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="是否弹框提醒" prop="popup">
        <select-maintain
          v-model="detail.form.data.popup"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => {
              params.paths = ['FLAG']
            }
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="阅读规则" prop="needCompleteRead">
        <select-maintain
          v-model="detail.form.data.needCompleteRead"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => {
              params.paths = ['FLAG']
            }
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="附件上传">
        <upload-maintain v-model="detail.form.data.attachments" multiple dir="record" accept=".pdf"></upload-maintain>
      </en-form-item>
      <en-form-item label="更新内容" prop="content">
        <quill-maintain v-model="detail.form.data.content" dir="record"></quill-maintain>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :loading="detail.form.loading" :method="detail.footer.confirm.click">确定</button-ajax>
      <button-ajax :loading="detail.form.loading" :method="detail.footer.publish.click">发布</button-ajax>
    </template>
  </en-drawer>

  <en-dialog v-model="preview.visible" title="更新内容">
    <en-scrollbar :height="600">
      <div v-html="preview.data.content"></div>
    </en-scrollbar>
  </en-dialog>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        add: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/release/note',
            data: 'array',
            loading: true
          },
          publish: {
            action: 'POST /enocloud/common/release/note/:releaseNoteId/publish'
          }
        },
        children: {
          title: {
            click(row: EnocloudCommonDefinitions['ReleaseNoteDto']) {
              this.detail.form.init()
              this.detail.form.data.id = row.id
              this.detail.form.get()
              this.detail.visible = true
            }
          },
          preview: {
            click(row: EnocloudCommonDefinitions['ReleaseNoteDto']) {
              this.preview.data.content = row.content ?? ''
              this.preview.visible = true
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            rules: {
              versionNumber: [{ required: true, message: '请输入版本号' }],
              title: [{ required: true, message: '请输入标题' }],
              subtitle: [{ required: true, message: '请输入副标题' }],
              launchDate: [{ required: true, message: '请输入上线日期' }],
              content: [{ required: true, message: '请输入内容' }]
            },
            ajax: {
              get: {
                action: 'GET /enocloud/common/release/note/:noteId',
                data: 'object',
                loading: true,
                init: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enocloud/common/release/note',
                validate: true,
                loading: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              update: {
                action: 'PUT /enocloud/common/release/note',
                validate: true,
                loading: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              publish: {
                action: 'POST /enocloud/common/release/note/:releaseNoteId/publish',
                loading: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            },
            publish: {
              async click() {
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                await this.detail.form.publish()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      },
      preview: {
        visible: false,
        data: {
          content: ''
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
